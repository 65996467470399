import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/share';
import { Platform } from '@ionic/angular';

import { Api, GlobalService } from "src/app/services";

import { Capacitor, Plugins } from '@capacitor/core';
const { LocalNotifications } = Plugins;

@Injectable()
export class Users {
	_user: any;
	
	constructor(
		private platform: Platform,
		public api: Api,
		public globalvar: GlobalService		
	) { }


	storePushkey(pushkeyreset = false){
		if(Capacitor.platform != 'web'){
			var push_permission;
			LocalNotifications.areEnabled().then((data)=>{
				push_permission = data.value ? data.value : 0;
				var user_id			= this.globalvar.myuser.id;
				var push_key 		= this.globalvar.pushkey;
				
				this.globalvar.myuser.push_key = this.globalvar.pushkey;
				
				if(pushkeyreset){
					push_key = '';
				}
				var push_type 		= this.platform.is('android')?'android':'ios';
				if(user_id){
					this.globalvar.setObject('user', this.globalvar.myuser);
	
					let seq = this.api.post('profile/store/pushkey', {'push_permission': push_permission, 'push_key': push_key, 'push_type': push_type, 'user_id': user_id}).share();
					
					seq.subscribe(
						(res: any) => {
							// If the API returned a successful response, mark the project as logged in
				
							
						},
						(err) => {
							console.error('ERROR', err);
						});
					
					return seq;		
							
				}
			});
		}
		
		
		
		
		
		
		
		
	}
	
	getProfile(id) {
		let seq = this.api.get('profile/'+id).share();
		
		seq.subscribe(
		(res: any) => {
			
		},
		(err) => {
			console.log('ERROR', err);
		});

		return seq;
	}
	
	saveProfile(user){
		let seq = this.api.post('profile/store', user).share();
		
		seq.subscribe(
			(res: any) => {
				// If the API returned a successful response, mark the project as logged in
	
				
			},
			(err) => {
				console.error('ERROR', err);
			});
		
		return seq;
	}

}
