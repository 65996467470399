import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/share';

import { Api, GlobalService } from "src/app/services";
import { environment } from 'src/environments/environment';



@Injectable()
export class Crates {
	_crates: any;
	
	constructor(
		public api: Api,
		public globalvar: GlobalService		
	) { }

	storeItems(items) {
		let seq = this.api.post('crates/store/items', items).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}

	getCrate(code){
		let seq = this.api.post('crates', code).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}

}
