import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { GlobalService } from 'src/app/services';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Sample } from 'src/app/services';

@Component({
  selector: 'app-multiselect-modal',
  templateUrl: './multiselect-modal.page.html',
  styleUrls: ['./multiselect-modal.page.scss'],

})
export class MultiselectModalPage {

  public total: string = '';

  _firstScan: string;
  _secondScan: string;
  is_untestable_1: boolean = false;
  is_untestable_2: boolean = false;
  location1: string = null;
  location2: string = null;
  lastSetValue = 1;

  active1: boolean = true;
  active2: boolean = false;
  _Is_desktop: boolean;
  _Is_mobile = false;

  _hideInput1;
  _hideInput2;


  constructor(
    private barcodeScanner: BarcodeScanner,
    public modalCtrl: ModalController,
    public zone: NgZone,
    public globalvar: GlobalService,
    private sample: Sample,
    private platform: Platform
  ) {
    this._Is_desktop = this.platform.is('desktop');
    this.globalvar.scanIsExecuted = this.scanResult.bind(this);

    if(!(this.globalvar.device.manufacturer).toLowerCase().includes('zebra') && this._Is_desktop == false){
      this._Is_mobile = true;
    }
  }

ngOnInit(){
  console.log(this._hideInput1, this._hideInput2);
}

  scanResult(intent: any, dateTime: String) {
    this.updateSamples(intent.extras['com.symbol.datawedge.data_string'])
    
  }

  updateSamples(code, position = null) {

    let sample = {
      scancodes: code
    };
    this.sample.getUnTestable(sample).subscribe((data) => {
      this.zone.run(() => {
        if (this.lastSetValue == 1) {
          this.is_untestable_1 = data['is_untestable'] == 1 ? true : false;
          this.location1 = data['location_details'];

        }
        else if (this.lastSetValue == 2) {
          this.is_untestable_2 = data['is_untestable'] == 1 ? true : false;
          this.location2 = data['location_details'];

        }
      });
    });

    this.zone.run(() => {
      console.log('updating front with', code);
      if (position == 1 || (position == null && this.active1 == true)) {
        this.lastSetValue = 1;
        this._firstScan = code;
        this.active1 = false;
        this.active2 = true;
      }
      else if (position == 2 || (position == null && this.active2 == true)) {
        this.lastSetValue = 2;
        this._secondScan = code;
        this.active1 = true;
        this.active2 = false;
      }
      this.calcCode();

    });
  }

  calcCode() {
    if (this._firstScan && this._secondScan) {
      var parts1 = this._firstScan.split(/ \- (?=[^ \- ]+$)/);
      var parts2 = this._secondScan.split(/ \- (?=[^ \- ]+$)/);
      
    
      //two barcodes are scanned
      if(this._firstScan != null && this._secondScan != null && parts1[1] == undefined && parts2[1] == undefined ){
        this.total = (parseInt(this._secondScan.replace(/S/g, '')) - parseInt(this._firstScan.replace(/S/g, '')) +1 ).toString();
      }
      else{
        if(parts1[1] == undefined && parts2[1] != undefined){
          parts1[1] = "0";
        }
        this.total = (parseInt(parts2[1]) - parseInt(parts1[1]) + 1).toString();
      }
    }
  }


  dismiss(data = null) {
    var codes = [];
    if (data) {
      codes = [
        this._firstScan,
        this._secondScan
      ];
    }

    this.modalCtrl.dismiss({
      'dismissed': true,
      'codes': codes
    });
  }


  Scan(location){
    if(location == 1){
      this.active1 = true;
      this.active2 = false;
    }
    else if(location == 2){
      this.active1 = false;
      this.active2 = true;
    }
  }

  mobileScan(location) {
  

      this.barcodeScanner.scan().then(barcodeData => {
        if(barcodeData.text != ''){

         this.updateSamples(barcodeData.text, location);
        }
      }).catch(err => {
        console.log('Error', err);
      });
    

  }

  DesktopChanges(location, value){
    if(location == 1){
      this.active1 = true;
      this.active2 = false;
    }
    else if(location == 2){
      this.active1 = false;
      this.active2 = true;
    }


    this.updateSamples(value, location);
  }

  clear1() {
    this._firstScan = null;
    this.location1 = null;
  }

  clear2() {
    this._secondScan = null;
    this.location2 = null;
  }
}
