import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GlobalService } from "src/app/services";
import { environment } from "src/environments/environment";

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable()
export class Api {
  token;

  constructor(public http: HttpClient, public globalvar: GlobalService) {}

  get(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        headers: new HttpHeaders(),
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    //    reqOpts.params = reqOpts.params.set('HTTP_X_AUTHORIZATION', this.globalvar.demo_token);
    let bearer = this.globalvar.token
      ? this.globalvar.token.token_type +
        " " +
        this.globalvar.token.access_token
      : "Bearer ";
    reqOpts.headers = reqOpts.headers.set("Authorization", bearer);

    return this.http.get(environment.API_URL + endpoint, reqOpts);
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        headers: new HttpHeaders(),
      };
    }

    let bearer = this.globalvar.token
      ? this.globalvar.token.token_type +
        " " +
        this.globalvar.token.access_token
      : "Bearer ";
    reqOpts.headers = reqOpts.headers.set("Authorization", bearer);
    reqOpts.headers = reqOpts.headers.set("Accept", "application/json");

    return this.http.post(environment.API_URL + endpoint, body, reqOpts);
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(environment.API_URL + endpoint, body, reqOpts);
  }

  delete(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        headers: new HttpHeaders(),
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    //    reqOpts.params = reqOpts.params.set('HTTP_X_AUTHORIZATION', this.globalvar.demo_token);
    let bearer = this.globalvar.token
      ? this.globalvar.token.token_type +
        " " +
        this.globalvar.token.access_token
      : "Bearer ";
    reqOpts.headers = reqOpts.headers.set("Authorization", bearer);

    return this.http.delete(environment.API_URL + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(environment.API_URL + endpoint, body, reqOpts);
  }
}
