import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/share';

import { Api, GlobalService } from "src/app/services";
import { environment } from 'src/environments/environment';



@Injectable()
export class Locations {
	_sample: any;
	
	constructor(
		public api: Api,
		public globalvar: GlobalService		
	) { }

	getLocations(id=''){
		if(id != ''){
			id = '/'+id;
		}
		let seq = this.api.get('locations'+id).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}

	getLocationsArchived(id='', inclArchive = false){
		if(id != ''){
			id = '/'+id;
		}
		let seq = this.api.post('locations'+id, {'inclArchive': inclArchive}).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}
	


}
