import { Component, Input, HostBinding, NgZone, EventEmitter, Output } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { MultiselectModalPage } from './multiselect-modal/multiselect-modal.page';
import { GlobalService } from 'src/app/services';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Sample } from 'src/app/services';

@Component({
  selector: 'app-multiscan',
  templateUrl: './multiscan.component.html',
  styleUrls: ['./multiscan.component.scss'],
})
export class MultiscanComponent {

  ngOnChanges(changes) {
    this.stateChanged();
    this.initScannerObject();
    console.log('value changed at ' + new Date());

  }

  @Input() set multiScanEnabled(val: boolean) {
    this._multiScanEnabled = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set testableValue(val: boolean) {
    this._testableValue = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set hideText(val: boolean) {
    this._hideText = (val !== undefined && val !== null) ? val : false;
  }


  @Input() set text(val: string) {
    this._text = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set subtext(val: string) {
    this._subtext = (val !== undefined && val !== null) ? val : '';
  }

  @Input() set hideInput(val: boolean) {
    this._hideInput = (val !== undefined && val !== null) ? val : false ;
  }

  @Input() set hideInput1(val: boolean) {
    this._hideInput1 = (val !== undefined && val !== null) ? val : false ;
  }
  @Input() set hideInput2(val: boolean) {
    this._hideInput2 = (val !== undefined && val !== null) ? val : false ;
  }



  @Input() active = false;
  @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() codes = [];
  @Output() codesChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() is_untestable = false;
  @Output() is_untestableChange: EventEmitter<any> = new EventEmitter<any>();

  @Output("onChange") onChange: EventEmitter<any> = new EventEmitter();

  @Output("onDelete") onDelete: EventEmitter<any> = new EventEmitter();


  _multiScanEnabled: boolean;
  _hideText: boolean;
  _testableValue: boolean;
  _text: string;
  _subtext: string = null;
  location: any;
  shouldInit = true;
  _Is_desktop: boolean;
  _Is_mobile = false;
  _hideInput = false;
  _hideInput1 = false;
  _hideInput2 = false;
  
  constructor(
    public modalCtrl: ModalController,
    public zone: NgZone,
    private platform: Platform,
    public globalvar: GlobalService,
    private barcodeScanner: BarcodeScanner,
    private androidPermissions: AndroidPermissions,
    private sample: Sample,
  ) {
    this._Is_desktop = this.platform.is('desktop');
    // if ((this.globalvar.device.manufacturer).toLowerCase().includes('zebra')) {
    //   this.initScanner(); // only init the scanner on zebra devices
    // }

    if(!(this.globalvar.device.manufacturer).toLowerCase().includes('zebra') && this._Is_desktop == false){
      this._Is_mobile = true;
    }
  }

  initScannerObject() {
    
    this.shouldInit = false;

    this.platform.pause.subscribe(async () => {
      this.shouldInit = true;
      (window as any).plugins.intentShim.unregisterBroadcastReceiver();

      // window.plugins.intentShim.unregisterBroadcastReceiver();
    });
    this.platform.resume.subscribe(() => {
      if (this.active && this.shouldInit) {
        this.initScannerObject();
      }
    });

    if (this.active) {
      this.globalvar.scanIsExecuted = this.scanResult.bind(this);
    }

  }

  scanResult(intent: any, dateTime: String) {
    this.updateSample(intent.extras['com.symbol.datawedge.data_string']);
    
  }

  DesktopChanges(value){
      this.updateSample(value);

      let sample = {
        scancodes: value
      };
  
      if(this._testableValue){
        this.checkUntestable(sample);
      }  

    
  }

  async openMultiScan() {

    if(!this.active){
      this.active = true;
      this.stateChanged();
    }
    
    const modal = await this.modalCtrl.create({
      component: MultiselectModalPage,
      cssClass: 'MultiselectModalPage',
      componentProps: {
        _hideInput1: this._hideInput1,
        _hideInput2: this._hideInput2
      }
    });
    modal.onWillDismiss().then((data) => {
      this.updateSample(data['data']['codes'][0], data['data']['codes'][1]);
      this.initScannerObject(); 
    });
    // 		const {data} = await modal.onWillDismiss();

    return await modal.present();
  }

  deleteSelection() {
    this.zone.run(() => {
      this.codes = [];
      this.active = true;
      this.location = null;
    });
    this.stateChanged();
    this.onDelete.emit();

  }

  updateSample(data, multi = null) {
    let sample = {
      scancodes: data
    };

    this.checkUntestable(sample);
    
    if (data) {
      this.zone.run(() => {
        this.codes[0] = data;
        if (multi) {
          this.codes[1] = multi;
        }
        this.active = false;
      })
    } else {
      this.initScannerObject(); // multiselect was pressed, but was not used so there is no scan reinit this to be able to scan
    }
    this.stateChanged();
  }

  stateChanged() {
    this.activeChange.emit(this.active);
    this.codesChange.emit(this.codes);
    this.is_untestableChange.emit(this.is_untestable);
    this.onChange.emit();
  }


  mobileScan() {
    console.log(this.globalvar.device.manufacturer);
    if ( !(this.globalvar.device.manufacturer).toLowerCase().includes('zebra')) {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
        (result) => {
          console.log('Has permission?', result.hasPermission);
          this.barcodeScanner.scan().then(barcodeData => {

            if(barcodeData.text != ''){
              this.updateSample(barcodeData.text);

              let sample = {
                scancodes: barcodeData.text
              };
  
              if(this._testableValue){
                this.checkUntestable(sample);
              }
            }
            
          }).catch(err => {
            console.log('Error', err);
          });
        },
        (err) => {
          //TODO notify scanner users?
          // this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA);
        }
      );

    }else{
      // //on scanner make this the active one
      // this.active = true;
      // this.stateChanged();
    }

  }


  Scan(){
    console.log(this.globalvar.device.manufacturer);
    if ( !(this.globalvar.device.manufacturer).toLowerCase().includes('zebra')) {
      // this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
      //   (result) => {
      //     console.log('Has permission?', result.hasPermission);
      //     this.barcodeScanner.scan().then(barcodeData => {

      //       if(barcodeData.text != ''){
      //         this.updateSample(barcodeData.text);

      //         let sample = {
      //           scancodes: barcodeData.text
      //         };
  
      //         if(this._testableValue){
      //           this.checkUntestable(sample);
      //         }
      //       }
            
      //     }).catch(err => {
      //       console.log('Error', err);
      //     });
      //   },
      //   (err) => {
      //     //TODO notify scanner users?
      //     // this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA);
      //   }
      // );

    }else{
      //on scanner make this the active one
      this.active = true;
      this.stateChanged();
    }
  }

  checkUntestable(sample) {
    this.sample.getUnTestable(sample).subscribe((data) => {
      this.zone.run(() => {

        this.is_untestable = data['is_untestable'] == 1 ? true : false;

        this.location = data['location_details'];
      });
    });

  }
}
