import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Injectable, NgZone } from "@angular/core";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { User } from "../../models/user";
import { NavController, MenuController, Platform } from "@ionic/angular";
import { AlertService, GlobalService, Users } from "src/app/services";

import { Analytics } from "capacitor-analytics";
import { Capacitor } from "@capacitor/core";
import { NavigationExtras } from "@angular/router";
const analytics = new Analytics();

@Injectable({
    providedIn: "root",
})
export class AuthService {
    isLoggedIn = false;
    token: any;
    constructor(
        private platform: Platform,
        private http: HttpClient,
        private navCtrl: NavController,
        public globalvar: GlobalService,
        private menu: MenuController,
        private alertService: AlertService,
        private users: Users,
        private zone: NgZone
    ) {}

    login(email: String, password: String) {
        return this.http
            .post(environment.API_URL + "auth/login", {
                email: email,
                password: password,
            })
            .pipe(
                tap((token) => {
                    this.globalvar.setObject("token", token).then(
                        () => {
                            console.log("Token Stored");
                            this.token = token;
                            this.globalvar.token = token;

                            this.user().subscribe((user) => {
                                this.globalvar.myuser = user;
                                if (
                                    !this.globalvar.myuser.is_active ||
                                    this.globalvar.myuser.is_active == "0"
                                ) {
                                    this.alertService.presentToast(
                                        "Uw acount is inactief",
                                        "danger"
                                    );
                                    this.globalvar.removeItem("token");
                                    this.isLoggedIn = false;
                                    this.navCtrl.navigateRoot("/login");

                                    return;
                                }

                                this.globalvar
                                    .setObject("user", user)
                                    .then(() => {
                                        console.log(user);
                                        console.log("Stored item!");
                                        this.users.storePushkey();

                                        this.isLoggedIn = true;
                                        this.menu.enable(true);
                                        analytics.setUserID({
                                            value: user.id.toString(),
                                        });
                                        analytics.logEvent({
                                            name: "login",
                                            params: { type: "manual" },
                                        });

                                        var route = "";
                                        // 					3 logistics, 4 project manager, 5 work preparator, 6 work spot
                                        switch (
                                            Number(
                                                this.globalvar.myuser.role_id
                                            )
                                        ) {
                                            case 3:
                                                route = "/new-package";
                                                break;
                                            case 4:
                                                route = "/quality-control";
                                                break;
                                            case 5:
                                                route = "/search-item";
                                                break;
                                            case 6:
                                                route = "/scan-item";
                                                break;
                                            default:
                                                route = "/scan-item";
                                                break;
                                        }

                                        if (Capacitor.getPlatform() == "web") {
                                            route = "/search-item";
                                        }

                                        this.navCtrl.navigateRoot(route, {
                                            replaceUrl: true,
                                        });
                                    });
                            });

                            return token;
                        },
                        (error) => {
                            console.error(
                                "Fout bij het opslaan van het token",
                                error
                            );
                        }
                    );
                })
            );
    }
    register(fName: String, lName: String, email: String, password: String) {
        return this.http.post(environment.API_URL + "auth/register", {
            fName: fName,
            lName: lName,
            email: email,
            password: password,
        });
    }
    forgottenpswd(email: String) {
        return this.http.post(environment.API_URL + "password/create", {
            email: email,
        });
    }

    idle(navigationExtras = null) {
        console.log(navigationExtras);
        if (navigationExtras == null) {
            navigationExtras = {
                queryParams: {
                    email: null,
                },
            };
        } else {
            navigationExtras = JSON.parse(navigationExtras);
        }
        this.zone.run(() => {
            this.globalvar.removeItem("token");
            this.isLoggedIn = false;
            delete this.token;
            this.navCtrl.navigateRoot(["/login"], navigationExtras);
        });
    }

    logout() {
        this.users.storePushkey(true);

        if (!this.token) {
            this.globalvar.removeItem("token");
            this.globalvar.clearStorage();

            this.isLoggedIn = false;
            this.navCtrl.navigateRoot("/login");
            return;
        }
        const headers = new HttpHeaders({
            Authorization:
                this.token["token_type"] + " " + this.token["access_token"],
        });
        return this.http
            .get(environment.API_URL + "auth/logout", { headers: headers })
            .pipe(
                tap((data) => {
                    this.globalvar.removeItem("token");
                    this.isLoggedIn = false;
                    delete this.token;
                    return data;
                })
            );
    }
    user() {
        if (!this.token) {
            console.log("no token yet");
            return;
        }
        const headers = new HttpHeaders({
            Authorization:
                this.token["token_type"] + " " + this.token["access_token"],
        });
        return this.http
            .get<User>(environment.API_URL + "auth/user", { headers: headers })
            .pipe(
                tap((user) => {
                    return user;
                })
            );
    }

    getToken() {
        return this.globalvar.getObject("token").then(
            (data) => {
                this.token = data;
                if (this.token != null) {
                    if (this.token.access_token == "") {
                        this.isLoggedIn = false;
                    } else {
                        this.isLoggedIn = true;
                    }
                } else {
                    this.isLoggedIn = false;
                }
            },
            (error) => {
                this.isLoggedIn = false;
            }
        );
    }

    badgeLogin(code: String) {
        let reqOpts = {
			headers: new HttpHeaders(),
		};
        

        let bearer = this.globalvar.token
            ? this.globalvar.token.token_type +
              " " +
              this.globalvar.token.access_token
            : "Bearer ";

        reqOpts.headers = reqOpts.headers.set("Authorization", bearer);
        reqOpts.headers = reqOpts.headers.set("Accept", "application/json");

        return this.http
            .post(environment.API_URL + "auth/badgeLogin", {
                code: code,
            }, reqOpts)
            .pipe(
                tap((token) => {
                    this.globalvar.setObject("token", token).then(
                        () => {
                            console.log("Token Stored");
                            this.token = token;
                            this.globalvar.token = token;

                            this.user().subscribe((user) => {
                                this.zone.run(() => {
                                    this.globalvar.myuser = user;
                                });
                                if (
                                    !this.globalvar.myuser.is_active ||
                                    this.globalvar.myuser.is_active == "0"
                                ) {
                                    this.alertService.presentToast(
                                        "Uw acount is inactief",
                                        "danger"
                                    );
                                    this.globalvar.removeItem("token");
                                    this.isLoggedIn = false;
                                    this.navCtrl.navigateRoot("/login");

                                    return;
                                }

                                this.globalvar
                                    .setObject("user", user)
                                    .then(() => {
                                        console.log(user);
                                        console.log("Stored item!");
                                        this.users.storePushkey();

                                        this.isLoggedIn = true;
                                        this.menu.enable(true);
                                        analytics.setUserID({
                                            value: user.id.toString(),
                                        });
                                        analytics.logEvent({
                                            name: "login",
                                            params: { type: "manual" },
                                        });
                                        var route = "";
                                        // 					3 logistics, 4 project manager, 5 work preparator, 6 work spot
                                        switch (
                                            Number(
                                                this.globalvar.myuser.role_id
                                            )
                                        ) {
                                            case 3:
                                                route = "/new-package";
                                                break;
                                            case 4:
                                                route = "/quality-control";
                                                break;
                                            case 5:
                                                route = "/search-item";
                                                break;
                                            case 6:
                                                route = "/scan-item";
                                                break;
                                            default:
                                                route = "/scan-item";
                                                break;
                                        }

                                        if (Capacitor.getPlatform() == "web") {
                                            route = "/search-item";
                                        }

                                        this.navCtrl.navigateRoot(route, {
                                            replaceUrl: true,
                                        });
                                    });
                            });

                            return token;
                        },
                        (error) => {
                            console.error(
                                "Fout bij het opslaan van het token",
                                error
                            );
                        }
                    );
                })
            );
    }
}
