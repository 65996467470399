import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forgottenpswd',
    loadChildren: () => import('./forgottenpswd/forgottenpswd.module').then( m => m.ForgottenpswdPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'user/profile/:id',
    loadChildren: () => import('./user/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'user/profile-edit/:id',
    loadChildren: () => import('./user/profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'privacypolicy',
    loadChildren: () => import('./privacypolicy/privacypolicy.module').then( m => m.PrivacypolicyPageModule)
  },
  {
    path: 'scan-item',
    loadChildren: () => import('./scan-item/scan-item.module').then( m => m.ScanItemPageModule)
  },
  {
    path: 'new-package',
    loadChildren: () => import('./new-package/new-package.module').then( m => m.NewPackagePageModule)
  },  
  {
    path: 'package-overview',
    loadChildren: () => import('./package-overview/package-overview.module').then( m => m.PackageOverviewPageModule)
  },
  {
    path: 'quality-control',
    loadChildren: () => import('./quality-control/quality-control.module').then( m => m.QualityControlPageModule)
  },
  {
    path: 'split-probe',
    loadChildren: () => import('./split-probe/split-probe.module').then( m => m.SplitProbePageModule)
  },
  {
    path: 'fill-crate',
    loadChildren: () => import('./fill-crate/fill-crate.module').then( m => m.FillCratePageModule)
  },
  {
    path: 'non-testable',
    loadChildren: () => import('./non-testable/non-testable.module').then( m => m.NonTestablePageModule)
  },
  {
    path: 'switch-user',
    loadChildren: () => import('./switch-user/switch-user.module').then( m => m.SwitchUserPageModule)
  },
  {
    path: 'search-item',
    loadChildren: () => import('./search-item/search-item.module').then( m => m.SearchItemPageModule)
  },
  {
    path: 'search-item-details',
    loadChildren: () => import('./search-item-details/search-item-details.module').then( m => m.SearchItemDetailsPageModule)
  },
  {
    path: 'search-location',
    loadChildren: () => import('./search-location/search-location.module').then( m => m.SearchLocationPageModule)
  },
  {
    path: 'search-location-details',
    loadChildren: () => import('./search-location-details/search-location-details.module').then( m => m.SearchLocationDetailsPageModule)
  },
  {
    path: 'search-crate',
    loadChildren: () => import('./search-crate/search-crate.module').then( m => m.SearchCratePageModule)
  },
  {
    path: 'selection-modal',
    loadChildren: () => import('./selection-modal/selection-modal.module').then( m => m.SelectionModalPageModule)
  },
  {
    path: 'item-notes/:id',
    loadChildren: () => import('./item-notes/item-notes.module').then( m => m.ItemNotesPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
