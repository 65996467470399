import { Component, Input, HostBinding, NgZone, ChangeDetectorRef } from '@angular/core';

// import { AppShellConfig } from '../config/app-shell.config';

@Component({
  selector: 'app-text-shell',
  templateUrl: './text-shell.component.html',
  styleUrls: ['./text-shell.component.scss']
})
export class TextShellComponent {
  // To debug shell styles, change configuration in the assets/app-shell.config.json file
//   private debugMode = (AppShellConfig.settings && AppShellConfig.settings.debug) ? AppShellConfig.settings.debug : false;  
  private debugMode = false;

  // tslint:disable-next-line:variable-name
  _data: string;
  _end: any;
  _value: string;

  @HostBinding('class.text-loaded') textLoaded = false;


  
  @Input() set finished(end: any){
  	this._end = end;
	if(this._end){
	    this.textLoaded = true;
    }
  }
  
  
  @Input() set data(val: string) {
      this._data = (val !== undefined && val !== null) ? val : '';
      
       if (this._data && this._data !== '' || this._end) {
		      this.textLoaded = true;
		    } else {
		      this.textLoaded = false;
		    }        

  }
    
    
  constructor( ) { 
	  
  }
}

  

  

