import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/share';

import { Api, GlobalService } from "src/app/services";
import { environment } from 'src/environments/environment';



@Injectable()
export class Sample {
	_sample: any;
	
	constructor(
		public api: Api,
		public globalvar: GlobalService		
	) { }

	getSamples(id='', type='', archived = false){
		if(id != ''){
			id = '/'+id;
		}
		if(type != ''){
			type = '/'+type;
		}
		let archived_str = (archived === true ? '/archived' : '');
		
		let seq = this.api.get('samples'+id+type+archived_str).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}

	getNotes(body = null){
		let seq = this.api.post('samples/notes', body).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}

	saveNote(body = null){
		let seq = this.api.post('samples/notes/save', body).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}

	deleteNote(id = null){
		let seq = this.api.delete('samples/notes/'+id).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}

	searchSamples(search, inclArchive){
		
		let searchvalue = {"searchvalue": search, "inclArchive": inclArchive};
		let seq = this.api.post('samples/search', searchvalue).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}
	
	storeSample(samples) {
		let seq = this.api.post('samples/store', samples).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}

	splitSample(sample) {
		let seq = this.api.post('samples/split', sample).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}

	setUnTestable(sample) {
		let seq = this.api.post('samples/set/untestable', sample).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}

	getUnTestable(sample) {
		let seq = this.api.post('samples/get/untestable', sample).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}

	getLastSplit(sample){
		let seq = this.api.post('samples/get/lastsplit', sample).share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}	
}
