import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/share';

import { Api, GlobalService } from "src/app/services";
import { environment } from 'src/environments/environment';



@Injectable()
export class Customers {
	_customers: any;
	
	constructor(
		public api: Api,
		public globalvar: GlobalService		
	) { }

	
	getCustomers() {
		let seq = this.api.get('customers').share();
		
		seq.subscribe(
		(res: any) => {
			// If the API returned a successful response, mark the project as logged in

			
		},
		(err) => {
			console.error('ERROR', err);
		});
		
		return seq;
	}
	
	getCustomersSearch(search) {
		let seq = this.api.post('customers/search', {'search': search}).share();
		
		seq.subscribe(
			(res: any) => {
				// If the API returned a successful response, mark the project as logged in
	
				
			},
			(err) => {
				console.error('ERROR', err);
			});
		
		return seq;
	}

}
