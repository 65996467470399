import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/share';

import { Api, GlobalService } from "src/app/services";
import { environment } from 'src/environments/environment';



@Injectable()
export class Feedback {
	_feedback: any;
	
	constructor(
		public api: Api,
		public globalvar: GlobalService		
	) { }

	
	sendFeedback(feedbackForm){
		let glob = Object.create(this.globalvar);
	
		let x =1;
		if(glob["app"]){		delete glob["app"]; }
		if(glob["alertCtrl"]){	delete glob["alertCtrl"]; }
		if(glob["storage"]){	delete glob["storage"]; }
		if(glob["platform"]){   delete glob["platform"]; }
		glob['environment'] = environment;
		
		feedbackForm.globalvar = JSON.stringify(glob);
		
		let seq = this.api.post('feedback/store', feedbackForm).share();
		
		seq.subscribe(
			(res: any) => {
				// If the API returned a successful response, mark the project as logged in
	
				
			},
			(err) => {
				console.error('ERROR', err);
			});
		
		return seq;
	}

}
