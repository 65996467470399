import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  public webSocket   = null;
  public ws_protocol = 'ws';
  public ws_hostname = '192.168.178.17';
  public ws_port     = 9001;
  public ws_endpoint = '';


//   String zpl = "^XA\r\n" + 
//   "^FO50,50\r\n" + 
//   "^B8N,100,Y,N\r\n" + 
//   "^FD1234567^FS\r\n" + 
//   "^XZ";
// String ip = "192.168.17.239"; // PRT-LH-03

// int port = 9100;

  constructor() { 

  }

  printLabel(label = null) {
    this.openWSConnection(this.ws_protocol, this.ws_hostname, this.ws_port, this.ws_endpoint);

    label = "^XA\r\n" + 
      "^FO50,50\r\n" + 
      "^B8N,100,Y,N\r\n" + 
      "^FD1234567^FS\r\n" + 
      "^XZ";
    this.webSocket.send(label);

    this.printHasEnded();
  }


  openWSConnection(protocol, hostname, port, endpoint) {
    var webSocketURL = null;
    webSocketURL = protocol + "://" + hostname + ":" + port + endpoint;
    console.log("openWSConnection::Connecting to: " + webSocketURL);
    try {
        this.webSocket = new WebSocket(webSocketURL);
        this.webSocket.onopen = function(openEvent) {
            console.log("WebSocket OPEN: " + JSON.stringify(openEvent, null, 4));
        };
        this.webSocket.onclose = function (closeEvent) {
            console.log("WebSocket CLOSE: " + JSON.stringify(closeEvent, null, 4));
        };
        this.webSocket.onerror = function (errorEvent) {
            console.log("WebSocket ERROR: " + JSON.stringify(errorEvent, null, 4));
        };
        this.webSocket.onmessage = function (messageEvent) {
            var wsMsg = messageEvent.data;
            console.log("WebSocket MESSAGE: " + wsMsg);
        };
    } catch (exception) {
        console.error(exception);
    }
  }

  printHasEnded() {
    this.webSocket.close();
  }

}
