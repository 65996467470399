import { Component, Input, ElementRef, Renderer2 } from '@angular/core';
import { IonContent, DomController } from '@ionic/angular';


@Component({
  selector: 'shrinking-segment-header',
  templateUrl: './shrinking-segment-header.component.html',
  styleUrls: ['./shrinking-segment-header.component.scss'],
})
export class ShrinkingSegmentHeader {

  @Input('scrollArea') scrollArea: IonContent;
  @Input('headerHeight') headerHeight: number;
  @Input('minHeaderHeight') minHeaderHeight: number;

  newHeaderHeight: any;

  constructor(public element: ElementRef, public renderer: Renderer2, private  domCtrl: DomController) {

  }

    ngAfterViewInit() {
  

  	let el = this.element.nativeElement;
  	let style = 'height';
  	let value = this.headerHeight + 'px';
  	
  	value == null ? this.renderer.removeStyle(el, style) : this.renderer.setStyle(el, style, value);

    this.scrollArea.ionScroll.subscribe((ev) => {
      this.resizeHeader(ev);
    });

  }

  resizeHeader(ev){

    this.domCtrl.write(async () => {
        const el = await this.scrollArea.getScrollElement();
		this.newHeaderHeight = this.headerHeight - el.scrollTop;
		
		if(this.newHeaderHeight < this.minHeaderHeight){
		this.newHeaderHeight = this.minHeaderHeight;
		}   
      
      
      
		let el2 = this.element.nativeElement;
		let style = 'height';
		let value = this.newHeaderHeight + 'px';
		
		value == null ? this.renderer.removeStyle(el2, style) : this.renderer.setStyle(el2, style, value);

      	


    });

  }

}